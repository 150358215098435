let TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtRotate.prototype.tick = function () {
    let i = this.loopNum % this.toRotate.length;
    let fullTxt = this.toRotate[i];
    let delta = 250 - Math.random() * 100;

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    let that = this;

    if (this.isDeleting) {
        delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};

window.onload = function () {
    let elements = document.getElementsByClassName('txt-rotate');
    for (let i = 0; i < elements.length; i++) {
        let toRotate = elements[i].getAttribute('data-rotate');
        let period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtRotate(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    let css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #f1f1f1 }";
    document.body.appendChild(css);
};


// Accordion

let acc = document.getElementsByClassName("list-item");
let i;

for (i = 0; i < acc.length; i++) {
    let panel = acc[i].querySelector('.list-description');
    acc[i].addEventListener("mouseover", function() {
        this.classList.add("active");
        panel.style.maxHeight = panel.scrollHeight + "px";
    });
    acc[i].addEventListener("mouseout", function() {
        this.classList.remove("active");
        panel.style.maxHeight = null;
    })
}

// Navigation

const animateNav = () => {
    const mobileIcon = document.querySelector(".mobile-icon");
    const navList = document.querySelector(".nav-list");
    const navItem = document.querySelectorAll(".nav-list li");
    const nav = document.querySelector("nav");

    mobileIcon.addEventListener("click", () => {
        if (navList.classList.contains("active")) {
            const body = document.body;
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
            navList.classList.remove("active");
        } else {
            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            const body = document.body;
            body.style.position = 'fixed';
            body.style.top = `-${scrollY}`;
            navList.classList.add("active");
        }

        let children = navList.children;
        for (let i = 0; i < children.length; i++) {
            if (children[i].style.animation) {
                children[i].style.animation = "";
            } else {
                children[i].style.animation = `navListFade 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) forwards ${i / 7}s`;
            }
        }
    });
};

animateNav();


setTimeout(window.addEventListener('scroll', function() {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);

    const nav = document.querySelector("nav");
    const navList = document.querySelector(".nav-list");
    if (window.scrollY === 0 && !navList.classList.contains("active")) {
        nav.classList.add("top");
    } else {
        nav.classList.remove("top");
    }
}), 100);
